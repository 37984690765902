<template>
  <Head>
    <title>Варианты покупки квартиры {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Варианты покупки квартиры на официальном сайте жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА - ипотека от банков-партнеров, лучшие предложения. Купите квартиру онлайн или офлайн."
    />
    <meta
      property="og:title"
      :content="'Варианты покупки квартиры ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="Варианты покупки квартиры на официальном сайте жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА - ипотека от банков-партнеров, лучшие предложения. Купите квартиру онлайн или офлайн."
    />
  </Head>
  <main class="main">
    <div class="container">
      <Breadcrumbs
        :links="[
          { name: 'Home', text: 'Главная' },
          { text: 'Варианты покупки' },
        ]"
      />
      <h1 class="page-title h2">Варианты покупки</h1>
      <div class="text">
        <div class="text__content">
          Купите квартиру онлайн или офлайн.
          <a
            href="javascript:void(0)"
            data-popup-with-chats
            data-popup-with-chats-title="Оставьте заявку"
            >Оставьте заявку</a
          >
          на&nbsp;просмотр и&nbsp;наш менеджер свяжется с&nbsp;Вами или
          <router-link :to="{ name: 'PlanArchive' }"
            >выберите квартиру</router-link
          >
          самостоятельно.
        </div>
        <div class="swiper-nav-top">
          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
      </div>
      <div v-if="mortgage" class="mortgage-table">
        <swiper
          v-if="addition_pay_program && addition_pay_program.length"
          :navigation="{
            prevEl: '.swiper-nav-top .swiper-button-prev',
            nextEl: '.swiper-nav-top .swiper-button-next',
          }"
          :slides-per-view="'auto'"
          :space-between="24"
          class="mortgage-feats"
        >
          <swiper-slide v-for="(program, i) in addition_pay_program" :key="i">
            <div
              :class="['mortgage-feats__item', { primary: program.pay_hint }]"
            >
              <div class="mortgage-feats__background" />
              <div class="mortgage-feats__content d-flex flex-column">
                <div
                  v-if="program.pay_name"
                  class="h6 mortgage-feats__title"
                  v-html="program.pay_name"
                />
                <div
                  v-if="program.pay_text"
                  class="mortgage-feats__text mt-auto"
                  v-html="program.pay_text"
                />
                <div class="mortgage-feats__link">
                  <a
                    class="more-link stretched-link"
                    :href="program.pay_link || 'javascript:void(0)'"
                    @click="
                      !program.pay_link
                        ? $openPopupWithChat(program.pay_name)
                        : false
                    "
                  >
                    Подробнее
                  </a>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="h3">Ипотека от банков-партнеров</div>
        <table v-if="mq.lgPlus">
          <thead>
            <tr>
              <th>Банк</th>
              <th>Процентная ставка</th>
              <th>Первоначальный взнос</th>
              <th>Срок кредитования</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in mortgage.project_banks" :key="item.bank_name">
              <td class="p-0">
                <div v-if="item.bank_image_url" class="mortgage-table__logo">
                  <img
                    :src="item.bank_image_url"
                    loading="lazy"
                    width="168"
                    height="56"
                    :alt="item.bank_name"
                  />
                </div>
              </td>
              <td>от&nbsp;{{ item.bank_bet }}%</td>
              <td>от&nbsp;{{ item.bank_min_pay }}%</td>
              <td>до&nbsp;{{ item.bank_max_time }}&nbsp;лет</td>
              <td>
                <a
                  class="more-link"
                  href="javascript:void(0)"
                  data-popup-with-chats
                  data-popup-with-chats-title="Подробнее"
                  >Подробнее</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="mortgage-mobile table-mobile">
          <div
            v-for="item in mortgage.project_banks"
            :key="item.bank_name"
            class="mortgage-mobile__item table-mobile__item"
          >
            <div v-if="item.bank_image_url" class="mortgage-mobile__logo">
              <img
                :src="item.bank_image_url"
                loading="lazy"
                width="168"
                height="56"
                :alt="item.bank_name"
              />
            </div>
            <div class="mortgage-mobile__info info info-small">
              <div class="info__row">
                <div class="info__td">
                  <span>Процентная ставка</span>
                </div>
                <div class="info__td">
                  <span>от&nbsp;{{ item.bank_bet }}%</span>
                </div>
              </div>
              <div class="info__row">
                <div class="info__td">
                  <span>Первоначальный взнос</span>
                </div>
                <div class="info__td">
                  <span>от&nbsp;{{ item.bank_min_pay }}%</span>
                </div>
              </div>
              <div class="info__row">
                <div class="info__td">
                  <span>Срок кредитования</span>
                </div>
                <div class="info__td">
                  <span>до&nbsp;{{ item.bank_max_time }}&nbsp;лет</span>
                </div>
              </div>
            </div>
            <div class="mortgage-mobile__more">
              <a
                class="more-link"
                href="javascript:void(0)"
                data-popup-with-chats
                data-popup-with-chats-title="Подробнее"
                >Подробнее</a
              >
            </div>
          </div>
        </div>
      </div>
      <BestFlats />
    </div>
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import BestFlats from "@/components/BestFlats.vue";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation]);
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
    BestFlats,
    Swiper,
    SwiperSlide,
  },
  inject: ["mainStore", "mq"],
  data() {
    return {};
  },
  computed: {
    mortgage() {
      return this.mainStore.state.data?.mortgage;
    },
    addition_pay_program() {
      return this.mainStore.state.data?.installment?.addition_pay_program;
    },
  },
};
</script>

<style scoped>
.main {
  overflow: hidden;
}

.text {
  margin-top: -15px;
  margin-bottom: 50px;
}

.text a {
  color: inherit;
}

.text__content {
  max-width: 700px;
  font-size: 20px;
}

body.-notouch .text a:hover {
  color: #205640;
}

.swiper-nav-top {
  position: static;
}

.mortgage-table {
  margin-bottom: 57px;
}

.mortgage-table td {
  padding-top: 28px;
  padding-bottom: 28px;
  font-weight: normal;
  font-size: 20px;
}

.mortgage-table__logo {
  max-width: 168px;
  text-align: center;
}

.mortgage-table__logo img {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  filter: grayscale(1);
}

.mortgage-mobile {
  padding-top: 0;
}

.mortgage-mobile__info .info__td:first-child {
  color: #8c8f8c;
}

.mortgage-mobile__logo {
  margin-bottom: 15px;
  text-align: center;
}

.mortgage-mobile__logo img {
  width: auto;
  max-width: 160px;
  height: auto;
  filter: grayscale(1);
}

.mortgage-mobile__more {
  margin-top: 15px;
}

.mortgage-feats {
  overflow: inherit;
  margin-bottom: 70px;
}

@media (min-width: 768px) {
  .text {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .mortgage-feats {
    margin-top: 40px;
  }
}

.mortgage-feats .swiper-slide {
  width: 288px;
  height: auto;
}

.mortgage-feats__item {
  position: relative;
  height: 100%;
  border: 1px solid #e5e5e5;
  transition: color 0.1s, transform 0.2s, box-shadow 0.2s;
}

.mortgage-feats__item.primary {
  border-color: #509c3b;
}

.mortgage-feats__item.primary .mortgage-feats__title {
  color: #205640;
}

.mortgage-feats__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #205640;
  opacity: 0;
  transition: opacity 0.1s;
}

.mortgage-feats__background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@i/text-mask.png");
  background-position: 50% 50%;
  background-size: 420px 560px;
  opacity: 0.4;
}

body.-notouch .mortgage-feats__item:hover,
body.-notouch .mortgage-feats__item:hover .mortgage-feats__title {
  color: #fff;
}

body.-notouch .mortgage-feats__item:hover {
  box-shadow: 0 10px 40px rgb(0 0 0 / 0.23);
  transform: translateY(-16px);
}

body.-notouch .mortgage-feats__item:hover .mortgage-feats__background {
  opacity: 1;
}

body.-notouch .mortgage-feats__item:hover .mortgage-feats__link a {
  color: #fff;
}

.mortgage-feats__content {
  position: relative;
  height: 100%;
  padding: 32px 24px;
}

.mortgage-feats__text {
  font-size: 16px;
}

.mortgage-feats__link {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .text {
    margin: -5px 0 40px;
    font-size: 16px;
  }

  .text__content {
    margin-bottom: 30px;
  }

  .mortgage-feats__content {
    padding: 24px;
  }

  .mortgage-feats__title {
    font-size: 24px;
  }
}
@media (max-width: 575px) {
  .mortgage-feats__title {
    margin-bottom: 10px;
  }
}
</style>
